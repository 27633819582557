<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>Accounting</h1>
        <bread-crumbs :breadcrumbs="breadcrumbs" />
      </div>
      <div class="header-aside paddingT16">
        <search />
      </div>
    </div>
    <tabs :tabs="tabs">
      <!-- <template #clientInvoicing><client-invoicing-tab @onDataLoaded="updateTab(tab, $event)" /></template> -->
       <!-- <template #PaymentDistribution><payment-distribution @onDataLoaded="updateTab(tab, $event)" /></template> -->
       <template #reconciliation><reconciliation @onDataLoaded="updateTab(tab, $event)" /></template>
      <!--<template #invoiceArchive><invoice-archive-tab @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #garnishmentSettings><garnishment-settings-tab @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #iifTest><iif-testing @onDataLoaded="updateTab(tab, $event)" /></template> -->
            <!-- <template #reports><distribution-report @onDataLoaded="updateTab(tab, $event)" /></template> -->
    </tabs>
  </fragment>
</template>
<script>
import { Tabs, BreadCrumbs, Search } from "@/components";
import { 
  // ClientInvoicingTab,
  // PaymentDistribution,
  Reconciliation
  // InvoiceArchiveTab,
  // GarnishmentSettingsTab,
  // DistributionReport,
  // IifTesting
        } from "@/modules/admin/accounting/index";

import { Fragment } from "vue-fragment";

export default {
  name: "Accounting",
  components: { 
    BreadCrumbs, 
    Tabs, 
    // ClientInvoicingTab, 
    // PaymentDistribution,
    Reconciliation, 
    // InvoiceArchiveTab, 
    // GarnishmentSettingsTab, 
    Fragment, 
    Search, 
    // DistributionReport, 
    // IifTesting 
    },
  data: function () {
    return {
      tabs: [
        // { key: "clientInvoicing", label: "Invoices" },
        // { key: "PaymentDistribution", label: "Payment Distributions" },
        { key: "reconciliation", label: "Reconciliation" }
        // { key: "invoiceArchive", label: "Invoice Archive" },
        // { key: "garnishmentSettings", label: "Garnishment Settings" },
        // { key: "reports", label: "Reports" },
        // { key: "iifTest", label: "IIF Comparison" },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [{ name: "Accounting" }];
    },
  },
  methods: {
    updateTab(selectedTab, error) {
      this.tabs.filter((tab) => tab.key === selectedTab.key).forEach((tab) => (tab.error = error));
      this.tabs = [...this.tabs];
    },
  },
};
</script>
